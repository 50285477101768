/*-----------------
    # Typography
-----------------*/
/* @import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,500,700,900|Poppins:200,300,400,400i,500,600,700i|Source+Sans+Pro&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;900&family=Roboto+Serif:opsz,wght@8..144,100;8..144,300;8..144,400;8..144,500;8..144,700;8..144,900&family=Roboto+Slab:wght@100;300;500;700;900&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;300;400;500;700;900&family=Roboto+Serif:opsz,wght@8..144,100;8..144,300;8..144,400;8..144,500;8..144,700;8..144,900&family=Roboto+Slab:wght@100;300;500;700;900&display=swap");



:root {
  --main-color-one: #fda94f;
  --main-color-two: #ff6e63;
  --heading-color: #101425;
  --paragraph-color: #666;
  /* --heading-font: "Poppins", sans-serif; */
  --heading-font: "Poppins", sans-serif;
  --body-font: "Open Sans", sans-serif;
  --body-font-size: 15px;
  --line-height30: 1.8;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
  font-family: var(--body-font);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

body {
  margin: 0;
  color: var(--paragraph-color);
  overflow-x: hidden;
  font-family: var(--body-font);
  line-height: var(--line-height30);
  font-size: var(--body-font-size);
}

h1 {
  font-size: 70px;
  line-height: 1.2333333333333333;

}

h2 {
  font-size: 45px;
  line-height: 1.3380952380952381;
}

h3 {
  font-size: 32px;
  line-height: 1.3833333333333333;
}

h4 {
  font-size: 24px;
  line-height: 1.3380952380952381;
}

h5 {
  font-size: 20px;
  line-height: 1.3380952380952381;
}

h6 {
  font-size: 16px;
  line-height: 1.2380952380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

p {
  color: var(--paragraph-color);
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 10px;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: inherit;
}

a:hover {
  color: var(--main-color-one);
}

pre {
  word-break: break-word;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ol {
  counter-reset: counter;
  padding-left: 0;
}

ol li {
  list-style: none;
  margin-bottom: 1rem;
}

ol li:before {
  counter-increment: counter;
  content: counter(counter);
  font-weight: 500;
  margin-right: 10px;
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

/*input and button type focus outline disable*/
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.no-gutter > [class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.no-gutter[class^="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.h-100vh {
  height: 100vh;
}

code {
  color: #faa603;
}

.check-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.check-list li {
  display: block;
  padding-left: 20px;
  position: relative;
  z-index: 0;
}
.check-list li:after {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "fontawesome";
  content: "\f105";
  color: var(--main-color);
}

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
  clear: both;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}

.comment-navigation .nav-previous > a,
.posts-navigation .nav-previous > a,
.post-navigation .nav-previous > a,
.comment-navigation .nav-next > a,
.posts-navigation .nav-next > a,
.post-navigation .nav-next > a {
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.comment-navigation .nav-previous:hover > a,
.posts-navigation .nav-previous:hover > a,
.post-navigation .nav-previous:hover > a,
.comment-navigation .nav-next:hover > a,
.posts-navigation .nav-next:hover > a,
.post-navigation .nav-next:hover > a {
  color: var(--main-color);
}

.comment-list li {
  list-style: none;
}

.h-100vh {
  height: 100vh;
}

.position-relative {
  position: relative;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 15px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Globals
--------------------------------------------------------------*/
.border-bottom-yellow {
  border-bottom: 1px solid var(--main-color-one);
}

.bg-gray {
  background: #f6f7fb;
}

.pd-top-60 {
  padding-top: 60px;
}

.pd-top-70 {
  padding-top: 70px;
}

.pd-top-80 {
  padding-top: 80px;
}

.pd-top-85 {
  padding-top: 85px;
}

.pd-top-90 {
  padding-top: 90px;
}

.pd-top-92 {
  padding-top: 92px;
}

.pd-top-100 {
  padding-top: 100px;
}

.pd-top-120 {
  padding-top: 120px;
}

.pd-bottom-60 {
  padding-bottom: 60px;
}

.pd-bottom-65 {
  padding-bottom: 65px;
}

.pd-bottom-70 {
  padding-bottom: 70px;
}

.pd-bottom-80 {
  padding-bottom: 80px;
}

.pd-bottom-85 {
  padding-bottom: 85px;
}

.pd-bottom-90 {
  padding-bottom: 90px;
}

.pd-bottom-92 {
  padding-bottom: 92px;
}

.pd-bottom-100 {
  padding-bottom: 100px;
}

.pd-bottom-120 {
  padding-bottom: 120px;
}

.mg-top-100 {
  margin-top: 100px;
}

.mg-bottom-100 {
  margin-bottom: 100px;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  float: left;
  clear: both;
  margin-right: 20px;
}

.alignright {
  float: right;
  clear: both;
  margin-left: 20px;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

.alignfull {
  margin: 1.5em 0;
  max-width: 100%;
}

.alignwide {
  max-width: 1100px;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
  display: block;
}

.updated:not(.published) {
  display: none;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
  clear: both;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*----------------------------------------
    # Unit test
------------------------------------------*/
.wp-link-pages a {
  margin: 0 5px;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.wp-link-pages {
  margin-bottom: 30px;
  margin-top: 25px;
}

.wp-link-pages span,
.wp-link-pages a {
  border: 1px solid #e2e2e2;
  padding: 5px 15px;
  display: inline-block;
}

.wp-link-pages .current,
.wp-link-pages a:hover {
  background-color: var(--main-color);
  color: #fff;
  border-color: var(--main-color);
}

.wp-link-pages span:first-child {
  margin-right: 5px;
}

dl,
ol,
ul {
  padding-left: 15px;
}

.post-password-form input {
  display: block;
  border: 1px solid #e2e2e2;
  height: 50px;
  border-radius: 3px;
  padding: 0 20px;
}

.post-password-form label {
  font-weight: 600;
  color: #333;
}

.post-password-form input[type="submit"] {
  width: 100px;
  height: 50px;
  background-color: var(--main-color);
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.post-password-form input[type="submit"]:hover {
  background-color: #121a2f;
}

.footer-widget .table td,
.footer-widget .table th {
  padding: 0.5rem !important;
}

@media only screen and (max-width: 1100px) {
  h4 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 991px) {
  h4 {
    font-size: 20px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1360px;
  }
}

/*---------------------------------------
    ## Button
---------------------------------------*/
.btn1 {
  height: 48px;
  line-height: 48px;
  padding: 0 30px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  font-family: "Poppins";
  border: 0;
  font-size: 14px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-weight: 600;
  /* margin-left:-100px; */
}

.btn:focus,
.btn:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-transform: scale(1, 0.5);
  -ms-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.btn:hover:before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.btn .right {
  padding-left: 12px;
  font-size: 16px;
}
.btn .left {
  padding-right: 15px;
}

.btn-yellow {
  background: var(--main-color-one);
  color: #fff;
}
.btn-yellow:hover,
.btn-yellow:focus {
  color: #fff;
}

.btn-blue {
  background: #002147;
  color: #fff;
}

.btn-blue:hover,
.btn-blue:focus {
  color: #fff;
}

.btn-grey {
  background: #c2c2c2;
  color: #fff;
}

.btn-grey:hover,
.btn-grey:focus {
  color: var(--main-color-two);
}

.readmore-btn {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-left: -15px;
}
.readmore-btn i {
  position: absolute;
  right: -32px;
  font-size: 24px;
  top: -1px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.readmore-btn:hover {
  color: var(--main-color-one);
}
.readmore-btn:hover i {
  right: -36px;
}

.btn-edit {
  font-size: 12px;
  font-family: "Poppins";
  color: #008cba;
  background-color: transparent;
  border: 0;
}

.btn-delete {
  font-size: 12px;
  font-family: "Poppins";
  color: #008cba;
  background-color: transparent;
  border: 0;
}

.review-edit-delete {
  margin-left: 5px;
  margin-bottom: 5px;
}

/*---------------------------------------------
    ## Feature list style
---------------------------------------------*/
.single-feature.style-two {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 30px 0;
  border: 1px solid #cfd3de;
  background-color: #fff;

  border-radius: 7px;
}

.single-feature.style-two .thumb {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 200px;
  border-radius: 5px 0 0 5px;
}

.single-feature.style-two .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  border-radius: 5px 0 0 5px;
}

.single-feature.style-two .details {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  padding: 20px 20px 20px 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.single-feature.style-two .details-button .btn .btn-secondary {
  margin: 500 0 300 0;
}

.single-feature.style-two .details .contact-list {
  position: relative;
  padding: 20px 0 0 0;
  visibility: visible;
  opacity: 1;
  bottom: 0;
  border-top: 0;
}

/*----------------------------------------------
    # Nav bar 
----------------------------------------------*/


.navbar-logo {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  /* display: contents; */
}



.navbar-area {
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  background: #fff;
  height: 100px;
  
}
.navbar-area.style-two {
  position: relative;
  height: 95px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  background: #fff;
}
.navbar-area .nav-container {
  background-color: transparent;
  padding: 22px 0px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  /* display: inline-block; */
}
.navbar-area .nav-container .logo a {
  font-family: var(--body-font);
}
.navbar-area .nav-container .logo a img {
  height: auto;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav {
  display: block;
  width: 100%;
  text-align: right;
  display: inline-block;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li {
  display: inline-block;
  font-weight: 500;
  line-height: 50px;
  text-transform: capitalize;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li a {
  color: #111;
  font-weight: 500;
  font-family: var(--heading-font);
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li:hover a {
  color: #111;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
  margin-left: 20px;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children {
  position: relative;
  z-index: 0;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children:before {
  position: absolute;
  right: -3px;
  top: 50%;
  /* content: '\f0d7'; */
  font-family: "fontawesome";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  color: var(--heading-color);
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children:hover {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children:hover
  > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu {
  position: absolute;
  text-align: left;
  min-width: 210px;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 0;
  top: 100%;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 9;
  overflow: hidden;
  /* visibility: hidden; */
  visibility: visible;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 4px;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu.border-bt0 {
  border-bottom: 0px !important;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  li {
  display: block;
  margin-left: 0;
  line-height: 22px;
  font-size: 15px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  li
  a {
  display: block;
  padding: 10px 20px;
  white-space: nowrap;
  color: var(--heading-color);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 14px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  li:hover {
  background: #ffeee2;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  li:hover
  a {
  color: var(--main-color-one);
  text-decoration: underline;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  .menu-item-has-children {
  position: relative;
  z-index: 0;
  padding-right: 0px;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  .menu-item-has-children:before {
  position: absolute;
  right: 15px;
  top: 50%;
  content: "\f105";
  font-family: "fontawesome";
  -ms-transform: translateY(-50%);
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  .menu-item-has-children
  > .sub-menu {
  left: 100%;
  top: 20px;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  .menu-item-has-children
  > .sub-menu
  .sub-menu
  .sub-menu {
  left: auto;
  right: 100%;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  .menu-item-has-children:hover
  > .sub-menu {
  visibility: visible;
  opacity: 1;
}
.navbar-area
  .nav-container
  .navbar-collapse
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  .menu-item-has-children:hover
  > .sub-menu
  li:hover:before {
  color: #fff;
}
.navbar-area .nav-container .navbar-collapse .navbar-nav > li {
  padding-right: 15px;
  margin-right: 18px;
}

.navbar-area-fixed {
  background: #fff;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.06);
}

.navbar-nav {
  opacity: 1;
  margin-right: -30px;
  visibility: visible;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  
}

.menu-open {
  opacity: 1;
  margin-right: 0;
  visibility: visible;
}

.bar1 {
  width: 32px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: #fff;
  z-index: 9999;
  top: 10px;
  right: -5px;
}

.bar2 {
  width: 24px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: #fff;
  z-index: 9999;
  top: 17px;
  right: -5px;
}

.bar3 {
  width: 18px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: #fff;
  z-index: 9999;
  top: 24px;
  right: -5px;
}

.responsive-mobile-menu button:focus {
  outline: none;
  border: none;
}


.toggle-btn {
  left: auto;
  right: 0;
  position: absolute;
  width: 40px;
  height: 40px;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  border: 0;
  background: transparent;
}
.toggle-btn .icon-left {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  height: 2px;
  width: 11px;
  top: 18px;
  background-color: var(--main-color-one);
  left: 7px;
}
.toggle-btn .icon-left:before {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--main-color-one);
  content: "";
  top: -7px;
  left: 0;
}
.toggle-btn .icon-left:after {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--main-color-one);
  content: "";
  top: 7px;
  left: 0;
}
.toggle-btn .icon-left:hover {
  cursor: pointer;
}
.toggle-btn .icon-right {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  height: 2px;
  width: 11px;
  top: 18px;
  background-color: var(--main-color-one);
  left: 18px;
}
.toggle-btn .icon-right:before {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--main-color-one);
  content: "";
  top: -7px;
  left: 0;
}
.toggle-btn .icon-right:after {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--main-color-one);
  content: "";
  top: 7px;
  left: 0;
}
.toggle-btn .icon-right:hover {
  cursor: pointer;
}
.toggle-btn.open .icon-left {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background: transparent;
}
.toggle-btn.open .icon-left:before {
  -webkit-transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
  -ms-transform: rotate(45deg) scaleX(1.4) translate(2px, 1px);
  transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
}
.toggle-btn.open .icon-left:after {
  -webkit-transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
  -ms-transform: rotate(-45deg) scaleX(1.4) translate(2px, -1px);
  transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
}
.toggle-btn.open .icon-right {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background: transparent;
}
.toggle-btn.open .icon-right:before {
  -webkit-transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
  -ms-transform: rotate(-45deg) scaleX(1.4) translate(-2px, 1px);
  transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
}
.toggle-btn.open .icon-right:after {
  -webkit-transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
  -ms-transform: rotate(45deg) scaleX(1.4) translate(-2px, -1px);
  transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
}
.toggle-btn:hover {
  cursor: pointer;
}

.nav-right-part-mobile {
  display: none;
}

.nav-right-part .btn {
  line-height: 46px;
}

