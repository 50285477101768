
  .addr_finder {
    display:block; 
    width:auto;
    width: 100%;
    height: 53px;
    /* border-color:#D3D3D3; */
    border:1px solid #C0C0C0;
    border-radius: 4px;
    font-size: 16px;


    /* width:1000px */
  }

  .image-upload {

    font-size: 16px;
    font-family: 'Poppins'
  }