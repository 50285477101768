/*----------------------------------------------
    ## Banner
----------------------------------------------*/
.banner-inner-wrap {
    height: 100vh;

    display: -webkit-box;
    display: -ms-flexbox;

    display: flex; 

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    margin: 25px 100px 25px 100px;
  } 
  
  .banner-inner-area {
    position: relative; 

  }

  .banner-inner .title {
    margin-left: -75px;
    margin-bottom: 20px;
    margin-top: -50px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    font-size: 40px;
    
   }
  

  .banner-inner .sub-title {
    color: var(--paragraph-color);
    margin-bottom: 10px;
    margin-left: -75px;
   }
  
  .banner-inner .content {
    margin-top: 20px;
    margin-bottom: 0; }
  
  .banner-inner .banner-btn-wrap {
    margin-top: 19px; }
  
  .banner-inner .btn {
    height: 55px;
    line-height: 55px; }
  
  .banner-inner .rld-banner-search {
    margin-top: 33px; }
  
  .thumb-wrap {
    position: absolute;
    bottom: 0; }
  
  /******* banner tab style *****/
  .banner-area {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
    margin-top: -25px;
    margin-bottom: -25px;
    
    /* margin-right: -20px; */

  }
    .banner-area .banner-search-wrap {
      margin-top: 24px;
      margin-right: -80px;
      margin-left: -80px;
    }
  
  .rld-banner-tab {
    border-bottom: 0; }
    .rld-banner-tab .nav-item {
      margin-bottom: 0; }
      .rld-banner-tab .nav-item a {
        border: 0;
        border-radius: 5px 5px 0 0;
        height: 50px;
        line-height: 50px;
        padding: 0 35px;
        margin-right: 8px;
        position: relative;
        display: block;
        letter-spacing: 0.4px;
        background: rgba(255, 255, 255, 0.8);
        color: var(--heading-color);
        font-family: 'Poppins';
        font-weight: 500;
        font-size:'medium';
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease; }

        .rld-banner-tab .nav-item a:hover, .rld-banner-tab .nav-item a:focus, .rld-banner-tab .nav-item a.active {
          background: var(--main-color-one);
          color: #fff; }

      .rld-banner-tab .nav-item:last-child {
        margin-bottom: 0; }
  
  .navbar-area-3 {
    background: #fff; }
  
  .banner-search {
    margin-top: 80px; }
  
  
 /*----------------------------------------------
    ## search page 
----------------------------------------------*/
.search-page-area {
    overflow: hidden; }
  
  .search-page-search-wrap {
    width: 45%;
    float: left;
    padding-right: 30px;
    position: fixed;
    z-index: 0; }
    .search-page-search-wrap #gmap {
      height: 100vh;
      margin-top: 90px; }
  
  .search-page-right-wrap {
    width: 55%;
    float: right; }
  
  .rld-search-page-tab {
    border-bottom: 0; }
    .rld-search-page-tab .nav-item {
      margin-bottom: 0; }
      .rld-search-page-tab .nav-item a {
        border: 0;
        padding: 0;
        text-align: center;
        border-radius: 5px;
        height: 48px;
        width: 48px;
        line-height: 52px;
        margin-right: 8px;
        position: relative;
        display: block;
        background: #FFEEE2;
        color: var(--main-color-one);
        font-family: var(--heading-font);
        font-weight: 600;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        font-size: 18px; }
        .rld-search-page-tab .nav-item a:hover, .rld-search-page-tab .nav-item a:focus, .rld-search-page-tab .nav-item a.active {
          background: var(--main-color-one);
          color: #fff; }
      .rld-search-page-tab .nav-item:last-child {
        margin-bottom: 0; }
  
  .search-page-right-side {
    margin-right: 50px;
    padding-top: 135px; }
    .search-page-right-side .section-title .title {
      font-size: 24px; }
    .search-page-right-side .single-feature .thumb img {
      width: 100%; }
    .search-page-right-side .rld-main-search {
      padding: 20px 25px 4px 25px;
      margin-bottom: 30px;
      border-radius: 6px;
      
    }
      
      .search-page-right-side .rld-main-search .colx {
        padding: 0 10px;
        display: inline-block; }
      .search-page-right-side .rld-main-search .col1 {
        width: 30%; }
      .search-page-right-side .rld-main-search .col2 {
        width: 19%; }
      .search-page-right-side .rld-main-search .col3 {
        width: 17%; }
  
  .search-page-map .single-feature {
    margin-bottom: 0; }
  
  .gm-style .gm-style-iw-c {
    padding: 16px; }
  
  .gm-ui-hover-effect {
    background: var(--main-color-one) !important;
    top: 0 !important;
    right: 0 !important;
    width: 30px !important;
    height: 30px !important;
    z-index: 9999 !important;
    border-radius: 0 10px 0 10px !important;
    opacity: 1 !important; }
  

    /*----------------------------------------------
    ## main search
----------------------------------------------*/
.main-search-area {
    margin-top: -50px; }
  
  .rld-main-search {
    padding: 50px 30px 35px 30px;
    background: #fff;
    -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 0 6px 6px 6px; 
    max-height:150px;
  }
    .rld-main-search .rld-single-input,
    .rld-main-search .rld-single-select .single-select {
      margin-bottom: 15px; }
    .rld-main-search .btn {
      width: 100%; }
  


  .rld-single-input {
    position: relative; }


    @media (max-width: 300px) {

      .rld-single-input input {
        height: 48px;
        line-height: 48px;
        width: 80%;
        padding: 0 5px;
        border: 1px solid #D4D4D4;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        border-radius: 4px;
        font-size: 16px; 
    
      
      }
    }
    @media (min-width: 300px) {

      .rld-single-input input {
        height: 48px;
        line-height: 48px;
        width: 100%;
        padding: 0 20px;
        border: 1px solid #D4D4D4;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        border-radius: 4px;
        font-size: 16px; 
    
      
      }
    }

      .rld-single-input input:hover, .rld-single-input input:active, .rld-single-input input:focus {
        border: 1px solid var(--main-color-one); }
    .rld-single-input.left-icon:before {
      content: '';
      /* background-image: url("../../assets/img/icons/1.png"); */
      position: absolute;
      top: 17px;
      background-size: auto;
      height: 17px;
      width: 12px;
      left: 19px;
      background-repeat: no-repeat; }
    .rld-single-input.left-icon input {
      padding-left: 20px; }
      
    .rld-single-input textarea {
      height: 120px;
      width: 100%;
      padding: 10px 20px;
      border: 1px solid #D4D4D4;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      border-radius: 4px;
      font-size: 16px; }
      .rld-single-input textarea:hover, .rld-single-input textarea:active, .rld-single-input textarea:focus {
        border: 1px solid var(--main-color-one); }
  
  .rld-single-select .single-select {
    height: 48px;
    line-height: 47px;
    width: 100%;
    padding: 0 34px 0 20px;
    border: 1px solid #D4D4D4;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    position: relative;
    border-radius: 4px; }
    .rld-single-select .single-select:hover, .rld-single-select .single-select:active, .rld-single-select .single-select:focus {
      border: 1px solid var(--main-color-one); }
    .rld-single-select .single-select:after {
      border: 0; }
    .rld-single-select .single-select:before {
      content: '';
      /* background-image: url("../../assets/img/icons/2.png"); */
      position: absolute;
      top: 19px;
      background-size: auto;
      height: 10px;
      width: 11px;
      right: 14px;
      left: auto;
      background-repeat: no-repeat; }
    .rld-single-select .single-select .list {
      width: 100%; }
  
  .rld-price-slider-wrap .rld-price-slider,
  .rld-price-slider-wrap .rld-size-slider {
    height: 5px;
    background: #FFF0E5;
    border: 0;
    margin: 20px 0 0 0; }
  
  .rld-price-slider-wrap .ui-slider-handle {
    height: 24px;
    width: 42px;
    background: var(--main-color-one);
    border: 0;
    text-align: center;
    color: #fff;
    border-radius: 30px;
    top: -10px;
    font-size: 13px;
    line-height: 25px; }
  
  /****** banner-search *******/
  .rld-banner-search .rld-single-input input {
    -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border: 0;
    padding-right: 140px; }
  
  .rld-banner-search .rld-single-input button {
    position: absolute;
    right: 0;
    top: 0;
    height: 48px;
    line-height: 48px;
    border: 0;
    padding: 0 20px;
    font-weight: 600;
    background: var(--main-color-one);
    color: #fff;
    cursor: pointer;
    border-radius: 0 4px 4px 0; }

    /*---------------------------------------------
    ## Explore
---------------------------------------------*/
.single-explore {
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 12px #97A1B21A;
    box-shadow: 0px 2px 12px #97A1B21A;
    overflow: hidden;
    margin-bottom: 30px; }
    .single-explore .thumb {
      position: relative; }
      .single-explore .thumb a {
        position: absolute;
        right: 20px;
        top: 20px;
        border-radius: 4px;
        background: #fff;
        color: #C5C5C5;
        height: 34px;
        width: 34px;
        line-height: 34px;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 16px;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        -webkit-box-shadow: 20px 0px 40px rgba(0, 0, 0, 0.3);
        box-shadow: 20px 0px 40px rgba(0, 0, 0, 0.3); }
    .single-explore .details {
      background: #fff;
      padding: 23px 25px; }
      .single-explore .details h4 {
        padding-left: 1px; }
      .single-explore .details .list {
        padding: 0;
        margin: 0;
        font-size: 16px; }
        .single-explore .details .list li {
          display: inline-block;
          list-style: none; }
          .single-explore .details .list li img,
          .single-explore .details .list li i {
            margin-right: 4px;
            color: var(--main-color-one); }
          .single-explore .details .list li:first-child {
            margin-right: 20px; }
    .single-explore:hover .thumb a {
      color: var(--main-color-one); }

      